import './index.css';
import LevantamientoIcon from "./images/Levantamientos-topográficos-planimétricos-y-altimétricos.png"
import InterventoriaIcon from "./images/Interventoría-en-topografía.png"
import AmarresIcon from "./images/Amarres-geodésico.png"
import LIDARIcon from "./images/LIDAR.png"
import TopografiaIcon from "./images/Topografía.png"
import ParcelacionesIcon from "./images/Diseño-de-parcelaciones.png"
import OrtomosaicoIcon from "./images/Ortomosaico.png"
import StratoIcon from "./images/Software-Strato.png"
import BatimetriaIcon from "./images/Batimetrías.png"
import DisenoGeomIcon from "./images/Diseño-geométrico-de-vías.png"
import ConstruccionViasIcon from "./images/Construcción-vías-terciarias.png"
import AlquilerIcon from "./images/Alquiler-de-maquinaria.png"
import SeguimientoIcon from "./images/Seguimiento-de-obras.png"
import PruebasCargaIcon from "./images/Pruebas-de-carga-estática.png"
import MonitoreoEstrucIcon from "./images/monitoreo-de-estructuras.png"
import Logo_completo from './icons/logo_claro_completo.png'
import Lang_es from "./icons/lang_es.png"
import logo_claro from "./images/dyc_logo_only.png"

import Home from "./pages/Home"

import React, { useState, useEffect, useRef } from 'react';
import {Link, BrowserRouter, Switch, Route, Router} from "react-router-dom";
//import { CSSTransition } from 'react-transition-group';

function App() {
  return (
      <BrowserRouter>
        <Navbar>
            <NavImage image = {Logo_completo}/>
            <NavCenter>
                <NavItem text={"Quienes Somos"} link={"/Empresa"} />
                <DropdownSource text={"Topografia \u25bf" } link={"/"}><DropdownMenuTopo/></DropdownSource>
                <DropdownSource text={"Obras Civiles \u25bf" } link={"/"}><DropdownMenuObras/></DropdownSource>

            </NavCenter>
            <NavContactUs text = {"Contacto"}/>
            <NavLanguage image={Lang_es}/>
        </Navbar>
      <Switch>
          <Route exact path='/' component={Home}/>
      </Switch>
      <BottomBar>

      </BottomBar>
      </BrowserRouter>
  );
}

function Navbar(props) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav-container">{props.children}</ul>
    </nav>
  );
}

function NavCenter(props) {
    return (
        <div className="navbar-center">
            <ul className="navbar-nav">{props.children}</ul>
        </div>
    );
}

function NavItem(props) {
  return (
    <li className="nav-item">
        <BrowserRouter>
          <Link to={props.link} className="icon-button">
            {props.text}
          </Link>
        </BrowserRouter>
        {props.children}
    </li>
  );
}

function NavImage(props) {
    return (
        <li className="nav-image">
            <BrowserRouter>
            <Link to="/" className="img-button">
                <img className="logo-img" src={props.image}/>
            </Link>
            </BrowserRouter>
        </li>
    );
}

function NavContactUs(props) {

    return (
        <a href="mailto:u.betancourt@disycon.co" className="nav-button" >

                <div className={"centered-div"} style={{"fontSize": "16px"}}>{props.text}</div>

        </a>

    );
}

function NavLanguage(props){
    return (
        <li className="nav-lang">
            <BrowserRouter>
                <Link to="/" className="img-button">
                    <img className="lang-img" src={props.image}/>
                </Link>
            </BrowserRouter>
        </li>
    );
}

function DropdownSource(props) {
    const [open, setOpen] = useState(false);
    return (
        <li className="nav-item" onMouseEnter={() => setOpen(true)}
            onMouseLeave={() =>setOpen(false)}>
            <BrowserRouter>
                <Link to={props.link} className="icon-button">
                    {props.text}
                </Link>
            </BrowserRouter>
            {open && props.children}
        </li>
    );
}



function DropdownMenuTopo(props) {


    function Div3(props){
        return (
            <div className="div-3">
                <BrowserRouter>
                    <Link to={props.link_1} className="menu-item-dropdown">
                        <span className="icon-left"><img className={"icon-left-img"} src={props.image_1}/></span>
                        {props.text_1}
                    </Link>
                </BrowserRouter>
                <BrowserRouter>
                    <Link to={props.link_2} className="menu-item-dropdown">
                        <span className="icon-left"><img className={"icon-left-img"} src={props.image_2}/></span>
                        {props.text_2}
                    </Link>
                </BrowserRouter>
                <BrowserRouter>
                    <Link to={props.link_3} className="menu-item-dropdown">
                        <span className="icon-left"><img className={"icon-left-img"} src={props.image_3}/></span>
                        {props.text_3}
                    </Link>
                </BrowserRouter>

            </div>
        );
    }

    return (
    <div className="dropdown">
        <Div3 link_1={"/1"} image_1={InterventoriaIcon} text_1={"Interventoria en topografía"}
              link_2={"/2"} image_2={AmarresIcon} text_2={"Amarre Geodésico"}
              link_3={"/3"} image_3={LIDARIcon} text_3={"LIDAR"}>
        </Div3>
        <Div3 link_1={"/4"} image_1={TopografiaIcon} text_1={"Topografía"}
              link_2={"/5"} image_2={LevantamientoIcon} text_2={"Levantamientos topográficos planimétricos y altimétricos"}
              link_3={"/6"} image_3={ParcelacionesIcon} text_3={"Diseño de parcelaciones"}>
        </Div3>
        <Div3 link_1={"/7"} image_1={OrtomosaicoIcon} text_1={"Ortomosaico"}
              link_2={"/8"} image_2={StratoIcon} text_2={"Software Strato"}
              link_3={"/9"} image_3={BatimetriaIcon} text_3={"Batimetrías"}>
        </Div3>
    </div>
  );
}

function DropdownMenuObras(props) {

    function Div3(props){
        return (
            <div className="div-3">
                <BrowserRouter>
                    <Link to={props.link_1} className="menu-item-dropdown">
                        <span className="icon-left"><img className={"icon-left-img"} src={props.image_1}/></span>
                        {props.text_1}
                    </Link>
                </BrowserRouter>
                <BrowserRouter>
                    <Link to={props.link_2} className="menu-item-dropdown">
                        <span className="icon-left"><img className={"icon-left-img"} src={props.image_2}/></span>
                        {props.text_2}
                    </Link>
                </BrowserRouter>
                <BrowserRouter>
                    <Link to={props.link_3} className="menu-item-dropdown">
                        <span className="icon-left"><img className={"icon-left-img"} src={props.image_3}/></span>
                        {props.text_3}
                    </Link>
                </BrowserRouter>

            </div>
        );
    }

    return (
        <div className="dropdown">
            <Div3 link_1={"/1"} image_1={DisenoGeomIcon} text_1={"Diseño geométrico de vías"}
                  link_2={"/2"} image_2={ConstruccionViasIcon} text_2={"Construcción vías terciarias"}
                  link_3={"/3"} image_3={AlquilerIcon} text_3={"Alquiler de maquinaria"}>
            </Div3>
            <Div3 link_1={"/4"} image_1={SeguimientoIcon} text_1={"Seguimiento de obras"}
                  link_2={"/5"} image_2={PruebasCargaIcon} text_2={"Pruebas de carga estática"}
                  link_3={"/6"} image_3={MonitoreoEstrucIcon} text_3={"Monitoreo de estructuras"}>
            </Div3>
        </div>
    );
}

function BottomBar(props) {
    return (
        <div className={"bottom-bar"}>
            <div className={"bottom-bar-inner"}>
                <div className={"div-image-fit"}>
                    <img src={logo_claro} style={{}}/>
                </div>

                <div style={{"padding":"5px", "textAlign":"center"}}>
                    <a className={"SectionSubHeader"}>Dirección</a>
                    <br/>
                    <a>Cra 43A #19-17 Medellín, Colombia</a>
                    <br/>
                </div>

                <div style={{"borderLeft":"3px solid #009BA2","padding":"5px","textAlign":"center"}}>
                    <a className={"SectionSubHeader"}>Contacto</a>
                    <br/>
                    <a href={"mailto: u.betancourt@disycon.co"}>Gerencia: u.betancourt@disycon.co</a>
                    <br/>
                    <a href={"mailto:pedro.gonzalez@eia.edu.co"}>Ingeniería: pedro.gonzalez@eia.edu.co</a>
                    <br/><br/>
                    <a>(4)5897999</a>
                </div>


            </div>
            <br/>
            <div className={"copyright"}>© Copyright 2021 Dis & Con</div>
        </div>
    );
}
export default App;
