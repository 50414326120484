import React from 'react';
import '../index.css';
import ouster_logo from '../images/ouster-logo.png'
import livox_logo from '../images/livox-logo.png'
import aceinna_logo from '../images/aceinna-logo.png'
import ublox_logo from '../images/ublox-logo.png'
import prototype from "../images/Prototipo.png"
import intel_i7 from "../images/intel-i7.png"
import aguacatala_gif from "../images/aguacatala.png"
import ros_logo from "../images/ros.png"
import wifi_logo from "../images/wifi-logo.png"
import {BrowserRouter, Link} from "react-router-dom";
import banner from "../images/banner.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';




export default class PointcloudNavigator  extends React.Component{
    constructor(props){
        super(props)
        this.potreeContainerDiv = React.createRef();
    }


    render(){
        return (

            <div>
            <div className={"fill-page"}>
                <div style={{"height" : "80vh"}}>
                    <a className={"SectionHeader"}>
                        Interactúe con los datos
                    </a>


                    {/*<iframe src = "viewer.html" width="100%" height="100%"  frameBorder="0"/>*/}
                    <Tabs direction={"rtl"}>
                        <TabPanel>
                            <a className={"SectionSubHeader"}>
                                Intercambio vial La Aguacatala
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Haga click sobre el visor para mover la nube de puntos
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Use scroll para hacer zoom y click derecho para desplazar la cámara.
                            </a>
                            <div style={{"height":"50vh", "minHeight":"500px"}}>
                                <iframe src = "viewer.html" width="100%" height="100%"  frameBorder="0"/>
                            </div>

                        </TabPanel>
                        <TabPanel>
                            <a className={"SectionSubHeader"}>
                                Levantamiento de vías y túneles
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Tunel de la quiebra con tramo de vía
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Tiempo de levantamiento: 5 minutos
                            </a>
                            <div style={{"height":"50vh", "minHeight":"500px"}}>
                                <iframe src = "viewer2.html" width="100%" height="100%"  frameBorder="0"/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <a className={"SectionSubHeader"}>
                                Levantamiento de túneles de minería
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Mina de oro en Titiribí, Antioquia
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Tiempo de levantamiento: 15 minutos
                            </a>
                            <div style={{"height":"50vh", "minHeight":"500px"}}>
                                <iframe src = "viewer5.html" width="100%" height="100%"  frameBorder="0"/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <a className={"SectionSubHeader"}>
                                Levantamiento de accidentes de tránsito
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Accidente cerca al intercambio La Aguacatala
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Tiempo de levantamiento: 2 minutos
                            </a>
                            <div style={{"height":"50vh", "minHeight":"500px"}}>
                                <iframe src = "viewer3.html" width="100%" height="100%"  frameBorder="0"/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <a className={"SectionSubHeader"}>
                                Interiores
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Preescolar la enseñanza
                            </a>
                            <a className={"SectionSubHeader"} style={{"fontSize": "13px"}}>
                                Tiempo de levantamiento: 5 minutos
                            </a>
                            <div style={{"height":"50vh", "minHeight":"500px"}}>
                                <iframe src = "viewer4.html" width="100%" height="100%"  frameBorder="0"/>
                            </div>
                        </TabPanel>
                        <TabList>
                            <Tab>Home</Tab>
                            <Tab>Vías y túneles</Tab>
                            <Tab>Minas</Tab>
                            <Tab>Accidentes de tránsito</Tab>
                            <Tab>Interiores</Tab>
                        </TabList>

                    </Tabs>
                    <div className={"div-4"}>
                        <a href={"https://ouster.com/"} className={"div-max-size"}>
                            <img src={ouster_logo}/>
                        </a>
                        <a href={"https://www.livoxtech.com/"} className={"div-max-size"}>
                            <img src={livox_logo}/>
                        </a>
                        <div href={"https://www.u-blox.com/en"} className={"div-max-size"}>
                            <img src={ublox_logo}/>
                        </div>
                        <div href={"https://www.aceinna.com/"} className={"div-max-size"}>
                            <img src={aceinna_logo}/>
                        </div>
                    </div>
                    <a className={"SectionHeader"}>
                        Desarrollamos un escaner. Usamos tecnología de punta.
                    </a>
                    <br/>
                    <div className={"centered-div"} style={{"marginBottom":"20px"}}>
                        <a href="mailto:u.betancourt@disycon.co" style={{"width":"20%"}} >
                            <div className={"centered_blue_button"}>
                                <div className={"centered-div"} style={{"fontSize": "16px"}}>Contáctenos</div>
                            </div>
                        </a>
                    </div>
                    {/*<div className={"centered-div"} style={{"marginBottom":"20px"}}>*/}
                    {/*<BrowserRouter>*/}
                    {/*    <Link to="/Contacto" style={{"width":"20%"}} >*/}
                    {/*        <div className={"centered_blue_button"}>*/}
                    {/*            <div className={"centered-div"} style={{"fontSize": "16px"}}>Saber más</div>*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</BrowserRouter>*/}
                    {/*</div>*/}
                </div>
            </div>
                <div style={{"width": "100%", "height": "20px"}}/>
            {/*<div className={"fill-page"}>*/}
            {/*    <div style={{"height" : "60vh"}}>*/}


            {/*        <div className={"div-comp"} style={{"marginTop":"20px"}}>*/}
            {/*            <div className={"overlap-div-left"}>*/}
            {/*                <div className={"div-image-fit"}>*/}
            {/*                    <img src={prototype}/>*/}
            {/*                </div>*/}
            {/*                <div className={"overlap-text"}>*/}
            {/*                    Nuestro equipo es una solución costoefectiva para*/}
            {/*                    procesos de mapeo terrestre y aéreo. Está equipado con un LIDAR*/}
            {/*                    de 128 canales que genera 2,621,440 puntos por segundo.*/}

            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <br/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*    <div style={{"width": "100%", "height": "20px"}}/>*/}
            {/*<div className={"fill-page"}>*/}
            {/*    <div style={{"height" : "60vh"}}>*/}
            {/*        <a className={"SectionHeader"}>*/}
            {/*            Levantamiento de accidentes de tránsito*/}
            {/*        </a>*/}
            {/*        <a className={"SectionSubHeader"}>*/}
            {/*            Accidente cerca a la aguacatala*/}
            {/*        </a>*/}
            {/*        <a className={"SectionSubHeader"}>*/}
            {/*            Tiempo de levantamiento: 2 minutos*/}
            {/*        </a>*/}
            {/*        <iframe src = "viewer3.html" width="100%" height="100%"  frameBorder="0"/>*/}

            {/*        <div className={"div-comp"} style={{"marginTop":"20px"}}>*/}
            {/*            <div className={"overlap-div-right"}>*/}

            {/*                <div className={"overlap-text"}>*/}
            {/*                    Cuenta con un avanzado sistema de procesamiento a bordo que le permite adquirir y*/}
            {/*                    posprocesar la gran cantidad de información con facilidad.*/}
            {/*                </div>*/}
            {/*                <div className={"div-image-fit"}>*/}
            {/*                    <img src={intel_i7} style={{"maxHeight":"80%"}}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <br/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*    <div style={{"width": "100%", "height": "40px"}}/>*/}
            {/*<div className={"fill-page"}>*/}
            {/*    <div style={{"height" : "60vh"}}>*/}
            {/*        <a className={"SectionHeader"}>*/}
            {/*            Interiores*/}
            {/*        </a>*/}
            {/*        <a className={"SectionSubHeader"}>*/}
            {/*            Preescolar La Enseñanza*/}
            {/*        </a>*/}
            {/*        <a className={"SectionSubHeader"}>*/}
            {/*            Tiempo de levantamiento: 5 minutos*/}
            {/*        </a>*/}
            {/*        <iframe src = "viewer4.html" width="100%" height="100%"  frameBorder="0"/>*/}

            {/*        <div className={"div-comp"} style={{"marginTop":"20px"}}>*/}
            {/*            <div className={"overlap-div-right"}>*/}

            {/*                <div className={"overlap-text"}>*/}
            {/*                    Tiene una interfaz de usuario accesible desde cualquier dispositivo con conexión WiFi*/}
            {/*                    que permite su operación incluso por personal no experimentado.*/}
            {/*                </div>*/}
            {/*                <div className={"div-image-fit"}>*/}
            {/*                    <img src={wifi_logo} style={{"height":"auto", "width":"80%"}}/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <br/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*    <div style={{"width": "100%", "height": "40px"}}/>*/}

        {/*<div style={{"height":"50px", "width":"100%"}}/>*/}
        {/*<div className={"centered-div"}>*/}
        {/*    <div className={"overlap-div-left"}>*/}
        {/*        <div className={"div-image-fit"}>*/}
        {/*            <img src={prototype}/>*/}
        {/*        </div>*/}
        {/*        <div className={"overlap-text"}>*/}
        {/*            Nuestro equipo se perfila como una solución costoefectiva para*/}
        {/*            procesos de mapeo terrestre y aéreo. Está equipado con un LIDAR*/}
        {/*            0S0-128 que genera 2,621,440 puntos por segundo.*/}

        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div style={{"height":"50px", "width":"100%"}}/>*/}
        {/*<div className={"centered-div"}>*/}
        {/*    <div className={"overlap-div-right"}>*/}

        {/*        <div className={"o   verlap-text"}>*/}
        {/*            Cuenta con un avanzado sistema de procesamiento a bordo que le permite adquirir y*/}
        {/*            posprocesar la gran cantidad de información con facilidad.*/}
        {/*        </div>*/}
        {/*        <div className={"div-image-fit"}>*/}
        {/*            <img src={intel_i7} style={{"maxHeight":"80%"}}/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className={"centered-div"}>*/}
        {/*    <div className={"overlap-div-left"}>*/}
        {/*        <div className={"div-image-fit"}>*/}
        {/*            <img src={ros_logo} style={{"maxWidth":"80%"}}/>*/}
        {/*        </div>*/}
        {/*        <div className={"overlap-text"} >*/}
        {/*            Ejecuta algoritmos de SLAM para complementar las diversas fuentes de odometría,*/}
        {/*            lo que permite reducir costos y aumentar el desempeño.*/}

        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className={"centered-div"}>*/}
        {/*    <div className={"overlap-div-right"}>*/}

        {/*        <div className={"overlap-text"}>*/}
        {/*            Tiene una interfaz de usuario accesible desde cualquier dispositivo con conexión wifi*/}
        {/*            que permite su operación incluso por personal no experimentado.*/}
        {/*        </div>*/}
        {/*        <div className={"div-image-fit"}>*/}
        {/*            <img src={wifi_logo} style={{"height":"auto", "width":"80%"}}/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}



        {/*<div>*/}
        {/*    <a className={"SectionHeader"}>*/}
        {/*        Aplicaciones*/}
        {/*    </a>*/}
        {/*    <div className={"centered-div"}>*/}
        {/*        <div className={"div-3-app"}>*/}
        {/*            <UseCase title={"Levantamientos Topográficos"} image={aguacatala_gif} description={"Descripcion"}/>*/}
        {/*            <UseCase title={"Inventarios Viales y Forestales"} image={aguacatala_gif} description={"Descripcion"}/>*/}
        {/*            <UseCase title={"Mapeo de Interiores"} image={aguacatala_gif} description={"Descripcion"}/>*/}
        {/*        </div>*/}
        {/*    </div>*/}

        {/*</div>*/}
        {/*<div className={"centered-div"} style={{"marginBottom":"20px"}}>*/}
        {/*        <a href="mailto:u.betancourt@disycon.co" style={{"width":"20%"}} >*/}
        {/*            <div className={"centered_blue_button"}>*/}
        {/*                <div className={"centered-div"} style={{"fontSize": "16px"}}>Contáctenos</div>*/}
        {/*            </div>*/}
        {/*        </a>*/}
        {/*</div>*/}
        <br/><br/><br/><br/>
    </div>


        )
    }
}

function UseCase(props) {
    return (
        <div className={"centered-div"} style={{"height": "100%", "width": "100%"}}>
            <div style={{"background":"#1F1F1F", "margin":"5px 5px 5px 5px","height": "100%", "width": "100%"}}>
            <div style={{"margin":"5px 5px 5px 5px","height": "100%", "width": "100%","borderTop":"3px solid #009BA2"}}>
                <a className={"SectionSubHeader"}>{props.title}</a>
                <div className={"div-image-fit"} style={{"height":"60%"}}>
                    <img src={props.image} />
                </div>
                <a className={"SectionSubHeader"}>{props.description}</a>
            </div>
            </div>
        </div>
    );
}

