import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const contanier = document.getElementById("app");
ReactDOM.render(<App />, contanier);
/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/
